<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    To Be Developed
                </v-card-title>
                <v-card-text>
                    <v-alert type="info">
                        Coming Soon - Tune in...
                    </v-alert>
                </v-card-text>
            </base-card>
        </v-col>        
    </v-row>
</template>

<script>
export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'To Be Developed'
    },
    data() {
        return {
            alert: true
        }
    }
}
</script>
